import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component"
import styled, { useTheme, keyframes } from "styled-components";

const Top = styled.div`
  width: 100%;
  display: block;
  max-width: 100%;
  gap: 12px;
`;

const Image = styled.img`
  height: 50px;
  border-radius: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Role = styled.div`
  font-size: 18px;
  font-weight: 600px;
  color: ${({ theme }) => theme.text_primary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const Company = styled.div`
  font-size: 14px;
  font-weight: 500px;
  color: ${({ theme }) => theme.text_secondary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const Date = styled.div`
  font-size: 12px;
  font-weight: 400px;
  color: ${({ theme }) => theme.text_secondary + 80};

  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const Skills = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: -10px;
`;
const Span = styled.div`
  display: -webkit-box;
  max-width: 100%;
`;

const Skill = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ExperienceCard = ({experience}) => {
	return (
		<VerticalTimelineElement
			icon={
				<img
					width= "100%"
					height= "100%"
					alt={experience?.company}
					style={{ borderRadius: "50%", objectFit: "cover"}}
					src={experience.img}
				/>
			}
			contentStyle={{
				display: "flex",
				flexDirection: "column",
				gap: "1em",
				background: "1d1836",
				color: "#fff",
				boxShadow: "rgba(23, 92, 230, 0.15) 0px 4px 24px",
				backgroundColor: "rgba(17, 25, 40, 0.83)",
				border: "1px solid rgba(255, 255, 255, 0.125)",
				borderRadius: "6px",
			}}
			contentArrowStyle={{
				borderRight: "7px solid  rgba(255, 255, 255, 0.3)",
			}}
			date={experience?.date}

		>
			<Top>
				<Image src={experience.img} />
				<Body>
					<Role>{experience.role}</Role>
					<Company>{experience.company}</Company>
					<Date>{experience.date}</Date>
				</Body>
				<Description>
					{experience?.desc && <Span>{experience.desc}</Span>}
					{experience?.skills && (
						<>
							<br/>
							<Skills>
								<b>Skills</b>
								<ItemWrapper>
								{experience?.skills?.map((skill,index) => (
									<Skill>• {skill}</Skill>
								))}
								</ItemWrapper>
							</Skills>
						</>
					)}
				</Description>
			</Top>

		</VerticalTimelineElement>
	)
}

export default ExperienceCard