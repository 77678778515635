import React from "react";
import { Link as LinkR } from "react-router-dom";
import styled, { useTheme, keyframes } from "styled-components";
import { Bio } from "../data/constants";
import { MenuRounded} from "@mui/icons-material"

const Nav = styled.div`
    background-color: ${({ theme }) => theme.bg};
    height: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    position:  sticky;
    top: 0;
    z-index: 10;
    color: white;

    @media screen and (max-width: 768px) {
        height: 3.5em;
    }
`;

const NavbarContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
`;

const NavLogo = styled(LinkR)`
    width: 80%;
    padding: 0 2em 0 2em;
    font-weight: 500;
    font-size: 1.5rem;
    text-decoration: none;
    color: inherit;
`;

const NavItems = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5em;
    padding: 0 2em;
    list-style: none;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavLink = styled.a`
    color: ${({ theme }) => theme.text_primary};
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        color: ${({ theme }) => theme.primary};
    }
`;

const ButtonContainer = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding 0 2em;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const GithubButton = styled.a`
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    padding: .75em 2em;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.6s ease-in-out;
    text-decoration: none;
    &:hover {
        background-color: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.text_primary};
    }
`;

const MobileIcon = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text_primary};
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const MobileMenu = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1em;
    padding: 0 2em;
    list-style: none;
    padding: 12px 40px 24px 40px;
    background: ${({ theme }) => theme.card_light + 99};
    position: absolute;
    top: 7em;
    right: 0;

    transition: all 0.6s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(-100%)")};
    border-radius: 0 0 20px 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    z-index: ${({ isOpen }) => (isOpen ? "1000" : "-1000")};

    /* Bounce animation */
    animation: bounce 0.5s;

    @keyframes bounce {
        0% { transform: translateY(-20px); }
        50% { transform: translateY(10px); }
        100% { transform: translateY(0); }
    }



    @media screen and (max-width: 768px) {
        top: 3.5em;
    }
`;


const Navbar = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const theme=useTheme();
    return (
        <Nav>
            <NavbarContainer>
                <NavLogo to="/">Georgijs</NavLogo>

                <MobileIcon onClick={() => setIsOpen(!isOpen)}>
                    <MenuRounded syle={{color: "inherit"}}/>
                </MobileIcon>

                <NavItems>
                      <NavLink href="#About">About</NavLink>
                      <NavLink href="#Skills">Skills</NavLink>
                      <NavLink href="#Experience">Experience</NavLink>
                      <NavLink href="#Projects">Projects</NavLink>
                      <NavLink href="#Education">Education</NavLink>
                      <NavLink target="_blank" href="https://blog.georgijs.dev">
                          Blog
                        </NavLink>
                    </NavItems>

                    {isOpen && (
                      <MobileMenu isOpen={isOpen}>
                        <NavLink onClick={() => setIsOpen(!isOpen)} href="#About">
                          About
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(!isOpen)} href="#Skills">
                          Skills
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(!isOpen)} href="#Experience">
                          Experience
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(!isOpen)} href="#Projects">
                          Projects
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(!isOpen)} href="#Education">
                          Education
                        </NavLink>
                        <NavLink onClick={() => setIsOpen(!isOpen)} target="_blank" href="https://blog.georgijs.dev">
                          Blog
                        </NavLink>
                        <GithubButton 
                            href={Bio.github} 
                            target="_Blank" 
                            style={{
                                background: theme.primary,
                                color: theme.text_primary,
                        }}>
                            Github Profile
                        </GithubButton>
                    </MobileMenu>
                )}


                <ButtonContainer>
                    <GithubButton href={Bio.github} target="Blank">Github Profile</GithubButton>
                </ButtonContainer>

            </NavbarContainer>
        </Nav>
    );
};

export default Navbar;
